import { gql } from "apollo-boost";
import { useQuery, useApolloClient } from "@apollo/react-hooks";

import article from "components/news-article/query";

import imageWaveText from "blocks/image-wave-text/query";
import imageRotateText from "blocks/image-rotate-text/query";
import ctaItems from "blocks/cta-items/query";
import headingTextLink from "blocks/heading-text-link/query";
import headingTextLinkImage from "blocks/heading-text-link-image/query";
import waveTextOnBackgroundImage from "blocks/wave-text-on-background-image/query";

import code from "blocks/code/query";
import richText from "blocks/rich-text/query";

import pageAlert from "blocks/page-alert/query";

import image from "blocks/image/query";
import video from "blocks/video/query";

import profileCta from "blocks/profile-cta/query";
import campaignCta from "blocks/campaign-cta/query";
import waveCta from "blocks/wave-cta/query";
import donateCta from "blocks/donatecta/query";
import clubDataPrivacyCenterPageQuery from "components/data-privacy-center-page/query";

import subscribeDownload from "blocks/subscribe-download/query";
import videoWaved from "blocks/video-waved/query";

import impactItems from "blocks/impact-items/query";
import arrowLink from "blocks/arrow-link/query";
import imageLink from "blocks/image-link/query";

import annualReports from "blocks/annual-reports/query";

import statistic from "blocks/statistic/query";
import pullquoteSimple from "blocks/pullquote-simple/query";
import gridBlock from "blocks/grid-block/query";

import infographic from "blocks/infographic/query";

import headingStatementText from "blocks/heading-statement-text/query";
import campaignHeader from "blocks/campaign-header/query";
import programComparison from "blocks/program-comparison/query";
import programDetail from "blocks/program-detail/query";
import programHeader from "blocks/program-header/query";
import programApplication from "blocks/program-application/query";
import impact from "blocks/impact/query";

import waysToGetInvolved from "blocks/ways-to-get-involved/query";
import servicesApply from "blocks/services-apply/query";
import clubApplication from "blocks/club-application/query";
import waysToFundraise from "blocks/ways-to-fundraise/query";
import partnerList from "blocks/partner-list/query";

import clubMapCta from "blocks/clubmapcta/query";
import programMap from "blocks/program-map/query";

import teams from "blocks/teams/query";

import campaignPullquote from "blocks/campaign-pullquote/query";

import solidBackground from "blocks/solid-background/query";
import theme from "blocks/theme/query";
import gradientWorm from "blocks/gradient-worm/query";

import newsFeed from "blocks/news-landing/query";

import nationHeader from "blocks/nation-header/query";
import partnerLogos from "blocks/partner-logos/query";

import recentNews from "blocks/recent-news/query";

import privacyCenterHeaderSection from "blocks/headerSection/query";
import privacyCenterLogoBadgeContentBlock from "blocks/logoBadgeContentBlock/query";
import privacyCenterQuoteContentBlock from "blocks/quoteContentBlock/query";
import {privacyCenterOurPrivacyApproachContentBlock, privacyCenterOurPrivacyApproachContentBlockItem} from "blocks/ourPrivacyApproachContentBlock/query";
import privacyCenterSecurityHighlightContentBlock from "blocks/securityHighlightContentBlock/query";
import {privacyCenterYourDataContentBlock, privacyCenterYourDataContentBlockItem} from "blocks/yourDataContentBlock/query";
import privacyCenterDataPrivacyAddendumSection from "blocks/dataPrivacyAddendumSection/query";
import {privacyCenterHelpfulResourcesContentBlock, privacyCenterHelpfulResourcesContentBlockItem} from "blocks/helpfulResourcesContentBlock/query";
import {privacyCenterAnchorLinks, privacyCenterAnchorLink} from "blocks/anchorLinks/query";

import { idtype, linkTo } from "querypieces";

const blocks = `
  ${imageRotateText}
  ${imageWaveText}
  ${ctaItems}
  ${headingTextLink}
  ${headingTextLinkImage}
  ${waveTextOnBackgroundImage}
  ${donateCta}
  ${image}
  ${video}
  ${profileCta}
  ${campaignCta}
  ${subscribeDownload}
  ${headingStatementText}
  ${programComparison}
  ${programDetail}
  ${programHeader}
  ${programApplication}
  ${impact}
  ${impactItems}
  ${arrowLink}
  ${waveCta}
  ${waysToGetInvolved}
  ${servicesApply}
  ${clubApplication}
  ${waysToFundraise}
  ${partnerList}
  ${campaignHeader}
  ${campaignPullquote}
  ${pageAlert}
  ${statistic}
  ${clubMapCta}
  ${programMap}
  ${pullquoteSimple}
  ${gridBlock}
  ${newsFeed}
  ${nationHeader}
  ${partnerLogos}
  ${infographic}
  ${code}
  ${richText}
  ${imageLink}
  ${recentNews}
  ...on pageBuilder_waveSeparator_BlockType {
    ${idtype}
  }
  ...on pageBuilder_locationModal_BlockType {
    ${idtype}
  }
  ${teams}
  ${videoWaved}
`;

const query = gql`
  query($uri: String) {
    entry(uri: [$uri]) {
        ${clubDataPrivacyCenterPageQuery} 
        
      ...on pages_page_Entry {
        typeHandle
        title
        slug
        showAlertBar
        abTest {
          id
        }
        pageBuilder {
          ${blocks}
          ${solidBackground(blocks)}
          ${theme(blocks)}
          ${gradientWorm(blocks)}
          ...on pageBuilder_conicGradientBackground_BlockType {
            ${idtype}
            children {
              ${blocks}
            }
          }
          ${annualReports}
          ...on pageBuilder_teams_BlockType {
            ${idtype}
            children {
              ${teams}
            }
          }
            ... on pageBuilder_headerSection_BlockType {
${privacyCenterHeaderSection}
            }
            ... on pageBuilder_anchorLinks_BlockType {
                ${privacyCenterAnchorLinks}
                children {
                    ... on pageBuilder_anchorLink_BlockType {
${privacyCenterAnchorLink}
                    }
                }
            }
            ... on pageBuilder_logoBadgeContentBlock_BlockType {
${privacyCenterLogoBadgeContentBlock}
            }
            ... on pageBuilder_quoteContentBlock_BlockType {
${privacyCenterQuoteContentBlock}
            }
            ... on pageBuilder_ourPrivacyApproachContentBlock_BlockType {
                ${privacyCenterOurPrivacyApproachContentBlock}
                children {
                    ... on pageBuilder_ourPrivacyApproachContentBlockItem_BlockType {
                        ${privacyCenterOurPrivacyApproachContentBlockItem}
                    }
                }
            }

            ... on pageBuilder_securityHighlightContentBlock_BlockType {
${privacyCenterSecurityHighlightContentBlock}
            }

            ... on pageBuilder_yourDataContentBlock_BlockType {
${privacyCenterYourDataContentBlock}
                children {
                    ... on pageBuilder_yourDataContentBlockItem_BlockType {
                        ${privacyCenterYourDataContentBlockItem}
                    }
                }
            }

            ... on pageBuilder_dataPrivacyAddendumSection_BlockType {
${privacyCenterDataPrivacyAddendumSection}
            }

            ... on pageBuilder_helpfulResourcesContentBlock_BlockType {
${privacyCenterHelpfulResourcesContentBlock}
                children {
                    ... on pageBuilder_helpfulResourcesContentBlockItem_BlockType {
${privacyCenterHelpfulResourcesContentBlockItem}
                    }
                }
            }
        }
      }
      ${article}
      ...on pages_iframePage_Entry {
        typeHandle
        iframeSrc
      }
      ...on pages_simpleTextPage_Entry {
        ${idtype}
        title
        richText
        links {
          ...on links_link_BlockType {
            ${idtype}
            ${linkTo}
          }
        }
      }
    }
    seomatic (uri: $uri) {
      metaTitleContainer
      metaTagContainer
    }
  }
`;

const usePage = (uri) => {
  const client = useApolloClient();

  let params = {
      client: client,
      notifyOnNetworkStatusChange: true
  };

  if (uri === "fr-ca" || uri === "hi-in") {
    params.context = {
      headers: { "X-GWC-Lang-Header": uri }
    }
  }

  if (!uri || uri === "index.html") {
    uri = "__home__";
  } else if (uri === "fr-ca") {
    uri = "en-ca";
  } else if (uri === "hi-in") {
    uri = "en-in";
  }

  params.variables = { uri };

  return useQuery(query, params);
};

export default usePage;
