import React, {useEffect, useRef, useState} from "react";
import { useSpring, useChain, a } from "react-spring";
import Image from "components/image";
import "./image-rotate-text.scss";

function findLongestWord(arr) {
  let longestWord = '';

  arr.forEach(word => {
    if (word.length > longestWord.length) {
      longestWord = word;
    }
  });

  return longestWord;
}

const ImageRotateText = ({ waveHeaderImages, richText, secondaryrichtext, plainText }) => {
  const main = waveHeaderImages[0].mainImage[0];
  const image2 = waveHeaderImages[0].image2[0];
  const image3 = waveHeaderImages[0].image3[0];

  const rotateInterval = 2000; //2 seconds
  const rotateText = (plainText ?? "").trim().split('|');

  const longestRotateWord = findLongestWord(rotateText);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % rotateText.length);
    }, rotateInterval);

    return () => clearInterval(interval);
  }, [rotateText.length]);

  const statementRef = useRef();
  const statementProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: statementRef,
  });

  const image1Ref = useRef();
  const image1Props = useSpring({
    from: { opacity: 0, transform: `translate(-10%,-10%)` },
    to: { opacity: 1, transform: `translate(0%,0%)` },
    ref: image1Ref,
  });

  const image2Ref = useRef();
  const image2Props = useSpring({
    from: { opacity: 0, transform: `translate(0%,10%)` },
    to: { opacity: 1, transform: `translate(0%,0%)` },
    ref: image2Ref,
  });

  const image3Ref = useRef();
  const image3Props = useSpring({
    from: { opacity: 0, transform: `translate(10%,10%)` },
    to: { opacity: 1, transform: `translate(0%,0%)` },
    ref: image3Ref,
  });

  useChain(
    [statementRef, image1Ref, image2Ref, image3Ref],
    [0.5, 0.75, 1, 1.25]
  );

  return (
    <div className="ImageRotateText space-for-nav">
      <a.div style={statementProps} className="ImageRotateText-text">
        <div className="ImageRotateText-text-line1" dangerouslySetInnerHTML={{__html: richText}}></div>
        <div className="ImageRotateText-text-line4">
          <div className={"ImageRotateText-rotating-safari-fix"}>{longestRotateWord}</div>
        </div>
        <div className="ImageRotateText-text-line2" dangerouslySetInnerHTML={{__html: secondaryrichtext}}></div>
        <div className="ImageRotateText-text-line3">
          {rotateText.map((item, index  ) => {
            return <div className={`ImageRotateText-text-line3-rotating ${index === currentIndex ? "active" : ""}`}>{item}</div>
          })}
        </div>
      </a.div>
      <a.div style={image1Props} className="ImageRotateText-image1">
        <Image {...main} />
      </a.div>
      <a.div style={image2Props} className="ImageRotateText-image2">
        <Image {...image2} />
      </a.div>
      <a.div style={image3Props} className="ImageRotateText-image3">
        <Image {...image3} />
      </a.div>
    </div>
  );
};

export default ImageRotateText;
