import React from "react";

//content blocks
import headingTextLink from "./heading-text-link";
import headingTextLinkImage from "./heading-text-link-image";
import clubMap from "./clubmapcta";
import ctaItems from "./cta-items";
import ctaItem from "./cta-items/cta-item";
import waveSeparator from "./waveseparator";
import image from "./image";
import video from "./video";
import impactItems from "./impact-items";
import arrowLink from "./arrow-link";
import statistic from "./statistic";
import infographic from "./infographic";
import code from "./code";
import richText from "./rich-text";
import locationModal from "./location-modal";

import imageLink from "./image-link";

import subscribeDownload from "./subscribe-download";
import pageAlert from "./page-alert";
import programMap from "./program-map";
import gridBlock from "./grid-block";

import annualReports from "./annual-reports";

import recentNews from "./recent-news";
import videoWaved from "./video-waved";

//cta
import profileCta from "./profile-cta";
import campaignCta, { Campaign as campaign } from "./campaign-cta";
import waveCta from "./wave-cta";
import donateCta from "./donatecta";

//program
import programComparison from "./program-comparison";
import programComparisonItem from "./program-comparison/program-comparison-item";
import programDetail from "./program-detail";
import programHeader from "./program-header";
import impact from "./impact";
import programApplication from "./program-application";

//get involved
import waysToGetInvolved from "./ways-to-get-involved";
import servicesApply from "./services-apply";
import clubApplication from "./club-application";
import partnerList from "./partner-list";
import waysToFundraise from "./ways-to-fundraise";

//Campaign
import campaignPullquote from "./campaign-pullquote";

//headers
import imageWaveText from "./image-wave-text";
import imageRotateText from "./image-rotate-text";
import headingStatementText from "./heading-statement-text";
import campaignHeader from "./campaign-header";
import waveTextOnBackgroundImage from "./wave-text-on-background-image";

//backgrounds
import conicGradientBackground from "./conicgradientbackground";
import solidBackground from "./solid-background";
import theme from "./theme";
import gradientWorm from "./gradient-worm";

//news article
import text from "./text";
import imageCaption from "./image-caption";
import pullquote from "./pullquote";

import pullquoteSimple from "./pullquote-simple";

//about: teams
import teams from "./teams";

import newsFeed from "./news-landing";

import nationHeader from "./nation-header";
import partnerLogos from "./partner-logos";
import headerSection from "./headerSection/headerSection";
import logoBadgeContentBlock from "./logoBadgeContentBlock";
import quoteContentBlock from "./quoteContentBlock/quoteContentBlock";
import ourPrivacyApproachContentBlock from "./ourPrivacyApproachContentBlock";
import securityHighlightContentBlock from "./securityHighlightContentBlock";
import yourDataContentBlock from "./yourDataContentBlock";
import helpfulResourcesContentBlock from "./helpfulResourcesContentBlock/helpfulResourcesContentBlock";
import anchorLinks from "./anchorLinks";
import dataPrivacyAddendumSection from "./dataPrivacyAddendumSection/dataPrivacyAddendumSection";

const components = {
  imageWaveText,
  imageRotateText,
  headingTextLink,
  headingTextLinkImage,
  donateCta,
  clubMap,
  ctaItems,
  ctaItem,
  pageAlert,
  waveTextOnBackgroundImage,
  waveSeparator,
  arrowLink,
  statistic,
  profileCta,
  campaignCta,
  waveCta,
  subscribeDownload,
  conicGradientBackground,
  theme,
  solidBackground,
  headingStatementText,
  programComparison,
  programComparisonItem,
  programDetail,
  programHeader,
  programApplication,
  impact,
  impactItems,
  text,
  pullquote,
  pullquoteSimple,
  imageCaption,
  waysToGetInvolved,
  waysToFundraise,
  servicesApply,
  clubApplication,
  partnerList,
  image,
  video,
  campaignHeader,
  campaign,
  campaignPullquote,
  teams,
  programMap,
  gradientWorm,
  annualReports,
  gridBlock,
  newsFeed,
  nationHeader,
  partnerLogos,
  infographic,
  richText,
  code,
  imageLink,
  recentNews,
  locationModal,
  videoWaved,

  //Privacy Data Center
  headerSection,
  logoBadgeContentBlock,
  quoteContentBlock,
  ourPrivacyApproachContentBlock,
  securityHighlightContentBlock,
  yourDataContentBlock,
  helpfulResourcesContentBlock,
  anchorLinks,
  dataPrivacyAddendumSection,
};

const Blocks = ({ blocks, entry }) => {
  return blocks.map((block) => {
    const Comp = components[block.typeHandle];
    if (!Comp) {
      console.warn(`Missing block type ${block.__typename}`);
      return null;
    }
    return <Comp key={block.id} {...block} entry={entry} />;
  });
};
export default Blocks;
